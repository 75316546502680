import React from 'react';
import ReactDOM from 'react-dom';
import '@synergycms_core/less/fonts.css';

import { Provider } from 'react-redux';


import App from './App';
import * as serviceWorker from './serviceWorker';
import ThemeProvider from '@synergycms_core/components/providers/themeProvider';
import ContentProvider from '@synergycms_core/components/providers/translateProvider';

import { defaults } from "@synergycms_core/utils";
import PopoverProvider from '@synergycms_core/components/providers/popoverProvider.jsx';
import { langCodeCorrector} from '@synergycms_core/utils/api';
import { is } from 'ramda';
import store from './configureStore.js';
import initializeStore from './start';

//Parametres basics

window.engineVersion = process.env.APP_VERSION;
console.log(window.engineVersion);

let data = null;

const setDefaults = (engineSygyData) => {
  let result = null;
  if (engineSygyData && engineSygyData.outer_web === false) {

    
    if (engineSygyData.lang) {
      engineSygyData.lang = langCodeCorrector(engineSygyData.lang);
    }

    result = defaults(engineSygyData || {}, {
      element_id: "default_sygy_element_engine",
      lang: langCodeCorrector(window.lang_info ? window.lang_info.code : "es"),
      engine_sygy_data: engineSygyData,
    });
  } else {
    result = defaults(engineSygyData || {}, {
      element_id: "default_sygy_element_engine", //element html on es carregara el motor
      lang: "es", //idioma actual en q es mostra el motor
      engine_sygy_data: engineSygyData // el mismo objecto q recibe el motor, sin perder el puntero en memoria, proporciana funciones al exterior para controlar el motor
    });
  }

  return result;
}


const  windowConfig =( window.frontLoader && window.frontLoader['cms-engine'] ) ? window.frontLoader['cms-engine'] : window.engine_sygy_data;

if (windowConfig) {
  if (is(Array, windowConfig)) {
    data = windowConfig.map((config) => {
      return setDefaults(config);
    });
  } else {
    data = setDefaults(windowConfig);
  }
} else {
  data = setDefaults({ outer_web: false });
  if (!data.hotel_code && !data.hotels_info) {
    data = {
      theme_values: {
        mainColor: "#c94",
        secondColor: '#5ca70a',
        backgroundColor: '#00111b',
        backgroundEngine : '#00111b',
        calendarColor: '#c94',
        colorFont: '#00111B',
        engineColorFont:'#c94',
        headerColorFont:'#c94',
        headerColorIcons:'#c94',
        fontFamily1: 'Roboto , sans-serif',
        fontFamily2: 'DroidSerif',
        fontFamily3: 'Icons',
        defaultColor: '#000'
      },
      outer_web: true,
      hotel_code: "SYN1275",
      url: "https://hoteldemo.booking-channel.com/disponibilidad.html",
      lang: "es",
      occups: { 0: { adults: 2, childs: 0 } },
      links: {},
      auto_open: true,
      element_id: "default_sygy_element_engine",
      web_type: "WI"
    }
  }
}


//Agreguem estils i element per defecte al body
if (process.env.NODE_ENV !== 'development') {
  /*const styleUrl = "https://synergy2.booking-channel.com/igm/synergycms_searchengine/build/static/css/main.css";
  if (document.createStyleSheet) {
    document.createStyleSheet(styleUrl);
  } else {
    var styles = "@import url('" + styleUrl + "');";
    var newSS = document.createElement('link');
    newSS.rel = 'stylesheet';
    newSS.href = 'data:text/css,' + escape(styles);
    document.getElementsByTagName("head")[0].prepend(newSS);
  }
  if (!is(Array, data) && !document.getElementById(data.element_id)) {
    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", data.element_id);
    document.body.prepend(newDiv);
  }*/
}
/*
window.engine_sygy_data = {
      outer_web: true,
      //hotels_info: hotels,
      hotel_code: "E092",
      url: "https://www.eurostarshotels.com/disponibilidad.html",
      lang: "es",
      //city_id: "25287",
      auto_open: true
    };
var engine = document.createElement('script');
engine.setAttribute('src', "https://synergy2.booking-channel.com/igm/synergycms_searchengine/build/static/js/main.js?nomemcache");
document.head.appendChild(engine);
*/
initializeStore(store.dispatch, store.getState, data);

const generateReactDoom = (config) => {
  ReactDOM.render(
    <Provider store={store}>
      <ContentProvider value={{
        lang: config.lang,
        theme: config.theme,
        i18n: config.i18n,
        links: config.links
      }}>
        <ThemeProvider value={{
          appId: config.element_id,
          theme: config.theme,
          themeValues: config.theme_values
        }}>
          <PopoverProvider>
            {config  ?
              <App data={config} /> :
              <div style={{ color: "red" }}>Error: load engine required params : url || hotels_info || hotel_code</div>}
          </PopoverProvider>
        </ThemeProvider>
      </ContentProvider>
    </Provider>, document.getElementById(config.element_id));
}

if (is(Array, data)) {
  data.forEach((config) => {
    generateReactDoom(config);
  });
} else {
  generateReactDoom(data);
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


