//import actions from 'store/actions';
import currency from '@synergycms_core/store/customer/currencyAction';

import customerActions from '@synergycms_core/store/customer/customerActions';

export default {
  ...customerActions,
  ...currency,
  CURRENT_CURRENCY: {
    SET: (currencyCode) => {
      return (dispatch, getState) => {
        let finalCurrency;
        const store = getState();
        const currencies = store.getIn(['customer', 'currencies']);

        const validateCurrency = currencyCode => currencies.find(c => currencyCode === c.get('code'));
        const hotels = store.getIn(['hotels', 'hotels_info', 'hotels']);
        const params = store.getIn(['customer', 'params', 'avail_cache_params']);
        if (currencyCode && validateCurrency(currencyCode)) {
          finalCurrency = currencyCode;
        } else if(hotels && params) {
          //date: selected.start,
          //city_id: !hotelId && selected.country_id ? selected.id : null,
          //country_id: !selected.country_id && !selected.code ? selected.id : null,
          //hotelId: hotelId
          
          if (params.get("hotelId")) {
            const hotelId = params.get("hotelId");
            currencyCode = hotels.find((hotel) => (hotel.get("id") === hotelId))?.get("currency_code");
            finalCurrency = validateCurrency(currencyCode) && currencyCode;
          } else if (!finalCurrency && params.get("city_id")) {
            const cityId = +params.get("city_id");
            currencyCode = hotels.find((hotel) => hotel.get("city_id") === cityId)?.get("currency_code");
            finalCurrency = validateCurrency(currencyCode) && currencyCode;
          } else if (!finalCurrency && params.get("country_id")) {
            const countryId = +params.get("country_id");
            currencyCode = hotels.find((hotel) => hotel.get("country_id") === countryId)?.get("currency_code");
            finalCurrency = validateCurrency(currencyCode) && currencyCode;
          } else {
            finalCurrency = "EUR";
          }
        }
        return finalCurrency;
      };
    }
  }
};



