import actions from './store/actions';
import moment from 'moment';
import { Map } from 'immutable';
import { find, omit } from 'ramda';
import { contextualizeAppConfig } from "@synergycms_core/store/app/utils";
import { setHeaderLang, momentLangCodes } from '@synergycms_core/utils/api';
import { toLower } from 'ramda';

const {
    hotels: hotelsActions,
    group: groupActions,
    app: appActions,
    customer : customerActions
} = actions;

const {
  webType: webTypeActions,
  params: paramsActions,
  langInfo: langActions,
  currencies: currenciesActions,
  currentCurrency : currentCurrencyActions,
  currentHotel: currentHotelActions
} = customerActions;


const initializeStore = (dispatch, getState, data) => {
  //dispatch(langActions.get(window.lang_info));
  

  let  windowConfig 
  if(window.frontLoader && window.frontLoader['cms-engine']){
    windowConfig = {
      ...window.frontLoader['cms-engine'],
      ...(window.frontLoader.hotels_info && { hotels_info: window.frontLoader.hotels_info }),
      ...(window.frontLoader.lang_info && { lang_info: window.frontLoader.lang_info }),    
      ...(window.frontLoader['cms-engine'].config?.url && {avail_url: window.frontLoader['cms-engine'].config?.url}),
      ...(window.frontLoader.avail_url && {avail_url: window.frontLoader.avail_url}),
      ...(window.frontLoader.currencies && {currencies: window.frontLoader.currencies})
    }
    window.engine_sygy_data = window.frontLoader['cms-engine'] //compatibilidad plantillas
  }else if(window.engine_sygy_data){
     windowConfig  = window.engine_sygy_data
  }

    dispatch(appActions.name.get("searchEngine"));
    let lang
    //Se tiene definido el lang (ya sea directamente o mediante gtm)
    if(windowConfig.lang_info || windowConfig.lang){
      lang = windowConfig.lang_info || { code : windowConfig.lang}
      
    }else{
      //No se tiene definido el lang, autodetectar html lang o navegador
      const userLang = document.documentElement.lang || navigator.language || navigator.userLanguage;
      lang = {code: userLang.split('-')[0] }
    }
    if(!window.lang_info){
      setHeaderLang(lang.code)
    }
    
    toLower(momentLangCodes(lang.code || "es"));
    moment.locale(lang.code);

    
    let { hotel_id, group, hotels_info, currencies, currency_code, hotel_code, config, i18n, lang_info, url, avail_url } = windowConfig;
    const hotelCode = hotel_code || window.hotel_code;
    let hotId = hotel_id || window.id_hotel || config?.hotel_id;
    group = group  || config?.group;
    
    
    let baseDataRequest = Promise.resolve(hotId);
    const appConfigName = 'cms-engine';

    let engineConfig 
    if(config){
      engineConfig= {
        ...(config && {
          config: {
            ...config,
          }
        }),
        ...(hotels_info && { hotels_info }), 
        ...(i18n && { i18n }),             
        ...(lang_info && { lang_info }),    
        ...(url && {avail_url: url}),
        ...(avail_url && {avail_url}),
      };
    }

    
    if (group === "eurostarshotelsficha") {
      group = "eurostarshotels";
    }
    group && dispatch(groupActions.get(group));
    
    
    if (!hotels_info && (hotId || hotelCode)) {
      let hotelRequest = Promise.resolve(hotId);
      if(!hotId) {
        hotelRequest = dispatch(hotelsActions.info.getAsync(hotelCode)).then(listId => {
          return listId[hotelCode];
        }).catch((err)=> {
          console.log(err);
        });
      }
      baseDataRequest = hotelRequest.then((resultId) => {
          hotId = resultId;
          dispatch(currentHotelActions.set(hotId));
          return dispatch(actions.app.config.getHotelAsync("cms-engine", hotId, engineConfig)).then(r => {
            
            if(r.getIn([hotId, 'success']) !== false && !r.getIn([hotId, appConfigName, 'ignore_synergy_config'], true)){
              const langInfo = r.getIn([hotId, 'lang_info']) || lang
              return Promise.all([
                dispatch(langActions.get(langInfo)),
                dispatch(webTypeActions.get("WI")),
                dispatch(appActions.outerWeb.get(r.getIn([hotId, appConfigName, 'outer_web'])))
              ])
            }
            
            //No usa config de synergy
            engineConfig = {
              config: {
                engine_active: r.getIn([hotId, appConfigName, 'engine_active'], true) ,
                prices_active: r.getIn([hotId, appConfigName, 'prices_active'], false),
                currency_code: r.getIn([hotId, appConfigName, 'currency_code']),
                ...omit(['lang_info', 'avail_url', 'i18n', 'config'], windowConfig),
                ...windowConfig.config,
                ignore_synergy_config: true,
              },
              lang_info: windowConfig.lang_info || r.getIn([hotId, 'lang_info']),
              avail_url: windowConfig.url ||  r.getIn([hotId, 'avail_url']),
              i18n: windowConfig.i18n || r.getIn([hotId, 'i18n']),
            }
            return dispatch(actions.app.config.getHotelAsync("cms-engine", hotId, engineConfig, true)).then(() => { 
              
              const langInfo = r.getIn([hotId, 'lang_info']) || lang
              return Promise.all([
                dispatch(langActions.get(langInfo)),
                dispatch(webTypeActions.get("WI")),
                dispatch(appActions.outerWeb.get(windowConfig.outer_web))
              ])
            })
            
          });
        }).catch((err)=> {
          console.log(err);
        });
    } else if(group) {
      baseDataRequest = dispatch(actions.app.config.getAsync("cms-engine", engineConfig)).then((r)=>{
        
        if(r.get('success') !== false && !r.getIn([appConfigName, 'ignore_synergy_config'], true)){
          const langInfo = r.get('lang_info') || lang
          return Promise.all([
            dispatch(langActions.get(langInfo)),
            dispatch(webTypeActions.get("PORTAL")),
            dispatch(appActions.outerWeb.get(r.getIn([appConfigName, 'outer_web']))),
            dispatch(hotelsActions.info.get(r.get('hotels_info'))),
          ])

        }

        //No usa config de synergy
        engineConfig = {
          config: {
            engine_active: r.getIn([appConfigName, 'engine_active'], true ) ,
            prices_active: r.getIn([appConfigName, 'prices_active'], false ),
            ...omit(['lang_info', 'avail_url', 'i18n', 'hotels_info', 'config'], windowConfig),
            ...windowConfig.config,
            ignore_synergy_config: true,
          },
          lang_info: windowConfig.lang_info ||  r.get('lang_info'),
          avail_url: windowConfig.url ||  r.get('avail_url'),
          i18n: windowConfig.i18n ||  r.get('i18n'),
          hotels_info: windowConfig.hotels_info ||  r.get('hotels_info')
        }

        return dispatch(actions.app.config.getAsync("cms-engine", engineConfig, true)).then((result) => {
          const langInfo = r.get('lang_info') || lang
          return Promise.all([
            dispatch(langActions.get(langInfo)),
            dispatch(webTypeActions.get("PORTAL")),
            dispatch(appActions.outerWeb.get(result.getIn([appConfigName, 'outer_web']))),
            dispatch(hotelsActions.info.get(result.get('hotels_info'))),
          ])

        })

        
        
      });
    }
    
    baseDataRequest.then(()=>{
      const engineConfigs = contextualizeAppConfig(getState(), hotId, "engine_config");
      if (engineConfigs.getIn([appConfigName, "prices_active"])) {
        const destination = !hotId && getDefaultDestination(hotels_info, windowConfig);
        const requiredAction = dispatch(currencies && currencies.length ?  currenciesActions.get(currencies) : currenciesActions.getAsync());
        if(destination) {
          destination && dispatch(paramsActions.availCacheParams(Map({
            city_id: destination.isCity && destination.id,
            country_id: destination.isCountry && destination.id,
            start: moment().format('YYYY-MM-DD')
          })));
        } else if(hotId) {
          dispatch(paramsActions.availCacheParams(Map({
            hotelId: hotId,
            start: moment().format('YYYY-MM-DD')
          })));
        }


        return Promise.resolve(requiredAction).then(() => {
          dispatch(currentCurrencyActions.set(engineConfigs.getIn([appConfigName, "currency_code"]) || currency_code, destination));
          if(getState().getIn(["customer", "current_currency"])) {
              return dispatch(actions.availCache.getAsync());
          }
        });
      }
    }).catch((err)=>{
        console.log(err);
    });
};

var getDefaultDestination = (hotelsInfo, params)=>{
  let result;
  
  if (!result && (params.city_id || params.config?.city_id || params.cityId)) {
    const cityId = params.city_id || params.config?.city_id || params.cityId;
    result = find(city => city.id.toString() === cityId.toString(), hotelsInfo.cities);
    if(result) {
      result.city_id = result.id;
     result.isCity = true;
    }
  }

  if (!result && (params.country_id || params.config?.country_id || params.countryId)) {
    const countryId = params.country_id || params.config?.country_id || params.countryId;
    result = find(country => country.id.toString() === countryId.toString(),  hotelsInfo.countries);
    if(result) {
      result.country_id = result.id;
      result.isCountry = true;
    }
  }

  return result;
};

export default initializeStore;