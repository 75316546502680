import actions from './store/actions';
import moment from 'moment';
import { Map } from 'immutable';
import { find, omit, toLower } from 'ramda';
import { contextualizeAppConfig } from "@synergycms_core/store/app/utils";
import { setHeaderLang, momentLangCodes } from '@synergycms_core/utils/api';

const {
    hotels: hotelsActions,
    group: groupActions,
    app: appActions,
    customer : customerActions,
    system: systemActions,
} = actions;

const {
  webType: webTypeActions,
  params: paramsActions,
  currencies: currenciesActions,
  currentCurrency : currentCurrencyActions,
  currentHotel: currentHotelActions
} = customerActions;


const initializeStore = (dispatch, getState, data) => {
  //dispatch(langActions.get(window.lang_info));
  
  dispatch(systemActions.get());
  let  windowConfig = {}
  if(window.frontLoader && window.frontLoader['cms-engine']){
    windowConfig = {
      ...window.frontLoader['cms-engine'],
      ...(window.frontLoader.hotels_info && { hotels_info: window.frontLoader.hotels_info }),
      ...(window.frontLoader.lang_info && { lang_info: window.frontLoader.lang_info }),    
      ...(window.frontLoader['cms-engine'].config?.avail_url && {avail_url: window.frontLoader['cms-engine'].config?.avail_url}),
      ...(window.frontLoader['cms-engine'].config?.url && {avail_url: window.frontLoader['cms-engine'].config?.url}),
      ...(window.frontLoader.currencies && {currencies: window.frontLoader.currencies}),
      ...(window.frontLoader.group && {group: window.frontLoader.group}),
      ...(window.frontLoader.web_type && {web_type: window.frontLoader.web_type}),
      ...(window.frontLoader.hotel_id && {hotel_id: window.frontLoader.hotel_id}),
      ...(window.frontLoader.hotel_code && {hotel_code: window.frontLoader.hotel_code}),
      ...(window.frontLoader.country_id && {country_id: window.frontLoader.country_id}),
      ...(window.frontLoader.city_id && {city_id: window.frontLoader.city_id}),
      ...(window.frontLoader.currency_code && {currency_code: window.frontLoader.currency_code}),
    }
    window.engine_sygy_data = window.frontLoader['cms-engine'] //compatibilidad plantillas
  }else if(window.engine_sygy_data){
     windowConfig  = window.engine_sygy_data
  }

    dispatch(appActions.name.get("searchEngine"));
    let lang
    //Se tiene definido el lang (ya sea directamente o mediante gtm)
    if(windowConfig.lang_info){
      lang = windowConfig.lang_info 
    }else if( windowConfig.lang ){ 
      lang = { code : windowConfig.lang}
    }else if( windowConfig.config?.lang){ 
      lang = { code: windowConfig.config.lang }
    }else{
      //No se tiene definido el lang, autodetectar html lang o navegador
      const userLang = document.documentElement.lang || navigator.language || navigator.userLanguage;
      lang = {code: userLang.split('-')[0] }
    }
    if(!window.lang_info){
      setHeaderLang(lang.code)
    }
    
    toLower(momentLangCodes(lang.code || "es"));
    moment.locale(lang.code);

    
    let { hotel_id, group, hotels_info, currencies, currency_code, hotel_code, config, i18n, lang_info, url, avail_url } = windowConfig;
    const hotelCode = hotel_code || window.hotel_code;
    let hotId = hotel_id || window.id_hotel;
    
    
    let baseDataRequest = Promise.resolve(hotId);

    let engineConfig = {
      config,
      ...(hotels_info && { hotels_info }), 
      ...(i18n && { i18n }),             
      ...(lang_info && { lang_info }),
      ...(url && {avail_url: url}),
      ...(avail_url && {avail_url}),
      ...(group && {group}),
    };

    if (group === "eurostarshotelsficha") {
      group = "eurostarshotels";
    }
    group && dispatch(groupActions.get(group));
    
    
    if (!hotels_info && (hotId || hotelCode)) {
      let hotelRequest = Promise.resolve(hotId);
      if(!hotId) {
        hotelRequest = dispatch(hotelsActions.info.getAsync(hotelCode)).then(listId => {
          return listId[hotelCode];
        }).catch((err)=> {
          console.log(err);
        });
      }
      baseDataRequest = hotelRequest.then((resultId) => {
          hotId = resultId;
          dispatch(currentHotelActions.set(hotId));
          return dispatch(actions.app.config.getHotelAsync("cms-engine", hotId, engineConfig)).then(r => {
            if(r.getIn([hotId, 'success']) !== false && !r.getIn([hotId, 'ignore_synergy_config'], true)){
              return Promise.all([
                dispatch(webTypeActions.get("WI")),
                dispatch(appActions.outerWeb.get(r.getIn([hotId, 'outer_web'])))
              ])
            }
            //No usa config de synergy
            //se genera configuracion teniendo en cuenta (engine_active, prices_active de la peticion)
            //de la configuracion recibida se omite lang, avail, i18n, config y currencies
            //se spreed los datos que pudiera tener de config
            //se añade la avail_url y i18n (de configuraciones o de la peticion en caso de faltar)
            engineConfig = {
              engine_active: r.getIn([hotId, 'engine_active'], true) ,
              prices_active: r.getIn([hotId, 'prices_active'], false),
              currency_code: r.getIn([hotId, 'currency_code']) ?? currency_code,
              ignore_synergy_config: true,

              ...omit(['lang_info', 'avail_url', 'url', 'i18n', 'config', 'currencies', 'group', 'web_type', 'hotel_id', 'hotel_code'], windowConfig),
              ...windowConfig.config,
              avail_url: windowConfig.url || windowConfig.avail_url ||   r.getIn([hotId, 'avail_url']),
              i18n: windowConfig.i18n || r.getIn([hotId, 'i18n']),
            }

            let groupAction = Promise.resolve();
            if(!group && r.getIn([hotId, 'group'], false)){
              groupAction = dispatch(groupActions.get(r.getIn([hotId, 'group'], false)))
            }
            return Promise.all([
              dispatch(webTypeActions.get("WI")),
              dispatch(appActions.outerWeb.get(engineConfig.outer_web)),
              dispatch(actions.app.config.getHotel(hotId, engineConfig)),
              groupAction
            ])
            
          });
        }).catch((err)=> {
          console.log(err);
        });
    } else if(group) {
      baseDataRequest = dispatch(actions.app.config.getAsync("cms-engine", engineConfig)).then((r)=>{
        
        if(r.get('success') !== false && !r.get('ignore_synergy_config', true)){
          return Promise.all([
            dispatch(webTypeActions.get("PORTAL")),
            dispatch(appActions.outerWeb.get(r.get('outer_web'))),
          ])

        }

        //No usa config de synergy
        //se genera configuracion teniendo en cuenta (engine_active, prices_active de la peticion)
        //de la configuracion recibida se omite lang, avail, i18n, hotels_info, config y currencies
        //se spreed los datos que pudiera tener de config
        //se añade la avail_url y i18n (de configuraciones o de la peticion en caso de faltar)
        engineConfig = {
          engine_active: r.get( 'engine_active', true ) ,
          prices_active: r.get( 'prices_active', false ),
          ignore_synergy_config: true,
          ...omit(['lang_info', 'avail_url', 'url', 'i18n', 'hotels_info', 'config', 'currencies', 'group', 'web_type', 'hotel_id', 'hotel_code'], windowConfig),
          ...windowConfig.config,
          avail_url: windowConfig.url || windowConfig.avail_url ||  r.get('avail_url'),
          i18n: windowConfig.i18n ||  r.get('i18n'),         
        }

        return Promise.all([
          dispatch(webTypeActions.get("PORTAL")),
          dispatch(appActions.outerWeb.get(engineConfig.outer_web)),
          dispatch(actions.app.config.get(engineConfig)),
        ])        
      });
    }
    
    baseDataRequest.then(()=>{
      const engineConfigs = contextualizeAppConfig(getState(), hotId, "engine_config");
      if (engineConfigs.get("prices_active")) {
        const destination = !hotId && getDefaultDestination(hotels_info, windowConfig);
        const requiredAction = dispatch(currencies && currencies.length ?  currenciesActions.get(currencies) : currenciesActions.getAsync());
        if(destination) {
          destination && dispatch(paramsActions.availCacheParams(Map({
            city_id: destination.isCity && destination.id,
            country_id: destination.isCountry && destination.id,
            start: moment().format('YYYY-MM-DD')
          })));
        } else if(hotId) {
          dispatch(paramsActions.availCacheParams(Map({
            hotelId: hotId,
            start: moment().format('YYYY-MM-DD')
          })));
        }


        return Promise.resolve(requiredAction).then(() => {
          dispatch(currentCurrencyActions.set(engineConfigs.get( "currency_code" ) || currency_code, destination));
          if(getState().getIn(["customer", "current_currency"])) {
              return dispatch(actions.availCache.getAsync());
          }
        });
      }
    }).catch((err)=>{
        console.log(err);
    });
};

var getDefaultDestination = (hotelsInfo, params)=>{
  let result;
  
  if (!result && (params.city_id || params.cityId)) {
    const cityId = params.city_id || params.cityId;
    result = find(city => city.id.toString() === cityId.toString(), hotelsInfo.cities);
    if(result) {
      result.city_id = result.id;
     result.isCity = true;
    }
  }

  if (!result && (params.country_id || params.countryId)) {
    const countryId = params.country_id || params.countryId;
    result = find(country => country.id.toString() === countryId.toString(),  hotelsInfo.countries);
    if(result) {
      result.country_id = result.id;
      result.isCountry = true;
    }
  }

  return result;
};

export default initializeStore;